import ChatHistory from './ChatHistory';

class ConversationSteram {
  query = '';

  chatHistory = [];

  constructor(query) {
    this.query = query;
    this.chatHistory = [];
  }

  addMessage(query, answer) {
    const chatHistoryMessage = new ChatHistory(query, answer);
    this.chatHistory.push(chatHistoryMessage);
  }

  addQuery(query) {
    this.query = query;
  }
}

export default ConversationSteram;
