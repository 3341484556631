import AIEndpoint from './AIEndpoint';

class AIDeployment {
  aideploymentId = 0;
  
  deploymentName = '';

  aideploymentTypeId = 0;

  aiendpoint = null;

  constructor(aiDeployment) {
    this.aiDeploymentId = aiDeployment.aiDeploymentId;
    this.deploymentName = aiDeployment.deploymentName;
    this.aiendpoint = new AIEndpoint(aiDeployment.aiendpoint);
  }
}

export default AIDeployment;
