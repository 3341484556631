import axios from 'axios';

class StarfishAssistant {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  endpoint = 'v2/assistant';

  axiosClient = axios;

  /**
   *
   * @param http {AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  /**
   *
   * @param conversation{AssistantConversation}
   * @returns {Promise<AssistantConversation>}
   */
  async ask(conversation) {
    try {
      conversation.removeOpenAIErrorMessages();
      
      console.log(conversation);
      const { data } = await this.http.post(`${this.endpoint}/ask`, conversation);

      if (conversation.id === '') {
        conversation.id = data.id;
      }

      // const { choices } = data;

      // (choices || []).forEach(({ message }) => {
      //   conversation.addAssistantMessage(message.content);
      // });
      
      const { content } = data;
      
      (content || []).forEach((contentItem) => {
        conversation.addAssistantMessage(contentItem.text);
      });

      return conversation;
    } catch (e) {
      console.log(e);
      const { errorMessage } = e.response.data;
      conversation.addErrorMessage(errorMessage);
      return conversation;
    }
  }

  async askRag({ 
    query,
    conversation,
    conversationSteram, 
    endpointInfo,
  }) {
    try {
      const { deploymentName, aiendpoint } = endpointInfo;
      const { 
        endpointUrl, 
        authorizationKey, 
        apimEndpointUrl, 
        apimSubscriptionKey,
      } = aiendpoint;
      // const url = 'https://wpp-aistudio-dev-apihub-mgmt.azure-api.net/vectorindex/score';
      // const apiKey = 'I64LSIy27DPa8mfKYOdi9KuuIcQk3I8g';
      const requestBody = conversationSteram; 
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authorizationKey}`,
        'azureml-model-deployment': deploymentName,
        'ocp-apim-subscription-key': apimSubscriptionKey,
      };

      const response = await this.axiosClient.post(apimEndpointUrl, requestBody, { headers });
      const { answer } = response.data;
      conversation.addAssistantMessage(answer);
      conversationSteram.addMessage(query, answer);
      return conversation;
    } catch (e) {
      console.log(e);
      const { error } = e;
      conversation.addErrorMessage(error?.message);
      return conversation;
    }
  }

  async indexCollections({ type, siteId }) {
    const { data } = await this.http.get(`${this.endpoint}/sites/${siteId}/collections/${type}`);
    return data;
  }
}

export default StarfishAssistant;
