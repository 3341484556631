class AIEndpoint {
  aiendpointId = 0;
  
  endpointUrl = '';

  authorizationKey = '';

  apimEndpointUrl = '';

  apimSubscriptionKey = '';

  constructor(aiEndpoint) {
    this.aiendpointId = aiEndpoint.aiendpointId;
    this.endpointUrl = aiEndpoint.endpointUrl;
    this.authorizationKey = aiEndpoint.authorizationKey;
    this.apimEndpointUrl = aiEndpoint.apimEndpointUrl;
    this.apimSubscriptionKey = aiEndpoint.apimSubscriptionKey;
  }
}
  
export default AIEndpoint;
