class TableauTokenClient {
  /**
   *
   * @param httpInstance{AxiosInstance}
   */
  constructor(httpInstance) {
    this.http = httpInstance;
    this.endpoint = 'v2/tableau';
  }

  async getToken(tableauUrl) {
    const { hostname } = new URL(tableauUrl);
    const { data } = await this.http.get(this.endpoint, { params: { tbh: hostname } });
    return data;
  }
}

export default TableauTokenClient;
