import { ApiFactory } from '@/api/api-factory';

const starfishAssistant = ApiFactory.getStarfishAssistant();

export default {
  async ask({ state }, message) {
    const { conversation } = state;

    conversation.addUserMessage(message);
    await starfishAssistant.ask(conversation);
  },

  async summarize({ state }, message) {
    const { summaryConversation, shortSummaryConversation } = state;
    summaryConversation.addSummarizeMessage(message);
    shortSummaryConversation.addSummarizeMessage(message);
    const result = await starfishAssistant.ask(summaryConversation);
    return result;
  },

  async summarizeBriefly({ state }, message) {
    const { shortSummaryConversation } = state;
    shortSummaryConversation.addUserMessage(message);
    const result = await starfishAssistant.ask(shortSummaryConversation);
    return result;
  },

  includeSummaryMessage({ state }, message) {
    const { summaryConversation } = state;
    summaryConversation.addSummarizeMessage(message);
  },

  async generateAssetCaseStudy({ state }, message) {
    const { caseStudyConversation } = state;
    caseStudyConversation.addCaseStudyMessage(message);
    const result = await starfishAssistant.ask(caseStudyConversation);
    return result;
  },

  async generateCustomCaseStudy({ state }, message) {
    const { customCaseStudyConversation } = state;
    customCaseStudyConversation.addCustomCaseStudyMessage(message);
    const result = await starfishAssistant.ask(customCaseStudyConversation);
    return result;
  },

  includeContentMessage({ state }, message) {
    const { conversation } = state;
    if (conversation.messages.length === 0) {
      conversation.addContentMessage(message);
    }
  },

  async extractThemes({ state }, message) {
    const { extractThemesConversation } = state;
    extractThemesConversation.addExtractThemesMessage(message);
    const result = await starfishAssistant.ask(extractThemesConversation);
    return result;
  },

  async extractTone({ state }, message) {
    const { extractToneConversation } = state;
    extractToneConversation.addExtractToneMessage(message);
    const result = await starfishAssistant.ask(extractToneConversation);
    return result;
  },

  async generateNewsLetterSummary({ state }, message) {
    const { newsletterConversation } = state;
    newsletterConversation.addNewsLetterMessage(message);
    const result = await starfishAssistant.ask(newsletterConversation);
    return result;
  },

  async generateCustomNewsletterCopy({ state }, message) {
    const { customNewsletterConversation } = state;
    customNewsletterConversation.addCustomNewsLetterMessage(message);
    const result = await starfishAssistant.ask(customNewsletterConversation);
    return result;
  },

  async generateSlidePresentation({ state }, message) {
    const { slidePresentationConversation } = state;
    slidePresentationConversation.addSlidePresentationMessage(message);
    const result = await starfishAssistant.ask(slidePresentationConversation);
    return result;
  },

  async generateCustomPresentationSlide({ state }, message) {
    const { customPresentationSlideConversation } = state;
    customPresentationSlideConversation.addCustomPresentationSlideMessage(message);
    const result = await starfishAssistant.ask(customPresentationSlideConversation);
    return result;
  },

  async generateSocialPostSummary({ state }, message) {
    const { socialpostConversation } = state;
    socialpostConversation.addSocialPostMessage(message);
    const result = await starfishAssistant.ask(socialpostConversation);
    return result;
  },

  async generateCustomSocialPostCopy({ state }, message) {
    const { customSocialpostConversation } = state;
    customSocialpostConversation.addCustomSocialPostMessage(message);
    const result = await starfishAssistant.ask(customSocialpostConversation);
    return result;
  },

  async generateInsightsSummary({ state }, message) {
    const { insightsConversation } = state;
    insightsConversation.addInsightsMessage(message);
    const result = await starfishAssistant.ask(insightsConversation);
    return result;
  },

  async continueConversation(_, { conversation, message }) {
    conversation.addUserMessage(message);
    const result = await starfishAssistant.ask(conversation);
    return result;
  },

  async getIndexCollections({ rootState }, type) {
    const { deploymentIndexId } = rootState
      .common.userDeployments.currentDeployment.currentIndex;

    const result = await starfishAssistant.indexCollections({
      type: type,
      siteId: deploymentIndexId,
    });
    return result;
  },

  async askQuestionOnCollection({ state }, { message, userPrompt }) {
    const { responseWriterConversation } = state;
    responseWriterConversation.addCollectionQueryMessage(message);
    responseWriterConversation.addUserMessage(userPrompt);
    const result = await starfishAssistant.ask(responseWriterConversation);
    return result;
  },

  async getSuggestions(_, conversation) {
    conversation.addSuggestionsMessage('');
    const result = await starfishAssistant.ask(conversation);
    const lastIndex = conversation.messages.length - 1;
    if (conversation.messages[lastIndex].role === 'assistant') {
      conversation.messages[lastIndex].role = 'assistant_suggestions';
    }
    return conversation;
  },

  initiateImageAnalyzerConversation({ state }, message) {
    const { imageAnalyzerConversation } = state;
    imageAnalyzerConversation.addImageAnalyzerMessage(message);
  },

  async analyzeImageData({ state }, message) {
    const { imageAnalyzerConversation } = state;
    imageAnalyzerConversation.addUserMessage(message);
    const result = await starfishAssistant.ask(imageAnalyzerConversation);
    return result;
  },

  initiatePreviewAnalyzerConversation({ state }, message) {
    const { previewAnalyzerConversation } = state;
    previewAnalyzerConversation.addImageAnalyzerMessage(message);
  },

  async analyzePreviewData({ state }, message) {
    const { previewAnalyzerConversation } = state;
    previewAnalyzerConversation.addUserMessage(message);
    const result = await starfishAssistant.ask(previewAnalyzerConversation);
    return result;
  },

  initiateImageAltTagConversation({ state }, message) {
    const { imageAltTagConversation } = state;
    imageAltTagConversation.addImageAnalyzerMessage(message);
  },

  async generateImageAltTag({ state }, message) {
    const { imageAltTagConversation } = state;
    imageAltTagConversation.addUserMessage(message);
    imageAltTagConversation.addUserMessage('Rewrite as Image ALT Tag');
    const result = await starfishAssistant.ask(imageAltTagConversation);
    return result;
  },

  initiateImageCaptionConversation({ state }, message) {
    const { imageCaptionConversation } = state;
    imageCaptionConversation.addImageAnalyzerMessage(message);
  },

  async generateImageCaption({ state }, message) {
    const { imageCaptionConversation } = state;
    imageCaptionConversation.addUserMessage(message);
    imageCaptionConversation.addUserMessage('Rewrite as an Image caption');
    const result = await starfishAssistant.ask(imageCaptionConversation);
    return result;
  },
  
  async initiateCollectionAssetsConversation({ state, dispatch }, message) {
    const { conversation } = state;
    if (conversation.messages.length === 0) {
      conversation.addCollectionQueryMessage(message);
    }
    const result = await dispatch('getSuggestions', conversation);
    return result;
  },

  async initiateCollectionInsightsConversation({ state }, message) {
    const { insightsConversation } = state;
    insightsConversation.addCollectionInsightsSummaryMessage(message);
    const result = await starfishAssistant.ask(insightsConversation);
    return result;
  },

  async summarizeCollectionAssets({ state }, message) {
    const { summaryConversation, shortSummaryConversation } = state;
    summaryConversation.addCollectionAssetsSummaryMessage(message);
    shortSummaryConversation.addCollectionAssetsSummaryMessage(message);
    const result = await starfishAssistant.ask(summaryConversation);
    return result;
  },

  async generateCustomShareMessage({ state }, message) {
    const { shareMessageConversation } = state;
    shareMessageConversation.addCustomShareMessage(message);
    const result = await starfishAssistant.ask(shareMessageConversation);
    return result;
  },

  async askRAG({ state }, { message, endpointInfo }) {
    const { ragConversation, ragConversationStream } = state;
    ragConversation.addUserMessage(message);
    ragConversationStream.addQuery(message);
    const result = await starfishAssistant.askRag({
      query: message,
      conversation: ragConversation,
      conversationSteram: ragConversationStream,
      endpointInfo: endpointInfo,
    });
    return result;
  },

  addPromptInstructions({ state }, message = '') {
    const { conversation } = state;
    if (conversation.messages.length === 0) {
      conversation.addPromptInstructionsMessage(message);
    }
  },

  resetConversations({ commit }) {
    commit('resetConversation');
    commit('resetSummaryConversation');
    commit('resetCaseStudyConversation');
    commit('resetExtractThemesConversation');
    commit('resetExtractToneConversation');
    commit('resetNewsletterConversation');
    commit('resetSlidePresentationConversation');
    commit('resetSocialpostConversation');
    commit('resetResponseWriterConversation');
    commit('resetImageAnalyzerConversation');
    commit('resetInsightsConversation');
  },

  async analyzeDocumentData({ state }, message) {
    const { documentAnalyzerConversation } = state;
    documentAnalyzerConversation.addDocumentAnalyzerMessage(message);
    const result = await starfishAssistant.ask(documentAnalyzerConversation);
    return result;
  },
};
