import Inputs from './Inputs';
import Outputs from './Outputs';

class ChatHistory {
  inputs = null;
  
  outputs = null;
  
  constructor(query, answer) {
    this.inputs = new Inputs(query);
    this.outputs = new Outputs(answer);
  }
}

export default ChatHistory;
