class AIDeploymentClient {
  http = null;
  
  endpoint = 'v2/aideployments';

  constructor(http) {
    this.http = http;
  }

  async getAIDeploymentInfo({ deploymentIndexId, aiDeploymentType }) {
    const params = {
      deploymentIndexId,
      aiDeploymentType,
    };
    const { data } = await this.http.get(`${this.endpoint}/getDeploymentInfo`, { params });
    console.log(data);
    return data;
  }
}

export default AIDeploymentClient;
